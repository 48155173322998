<template>
  <div>
    <img class="img" src="@/assets/tikkon/tikkon_3/tikkon_3_01.png" alt="">
    <img class="img" src="@/assets/tikkon/tikkon_3/tikkon_3_02.png" alt="">
    <img class="img" src="@/assets/tikkon/tikkon_3/tikkon_3_03.png" alt="">
    <img class="img" src="@/assets/tikkon/tikkon_3/tikkon_3_04.png" alt="">
    <div class="click">
      <img class="img" src="@/assets/tikkon/tikkon_0/tikkon_0_03.jpg" alt="">
      <div class="btn_list">
        <div class="btn" @click="clickTaba(3)"></div>
        <div class="btn" @click="clickTaba(2)"></div>
        <div class="btn" @click="clickTaba(5)"></div>
        <div class="btn" @click="clickTaba(4)"></div>
        <div class="btn" @click="clickTaba(0)"></div>
        <div class="btn" @click="clickTaba(1)"></div>
      </div>
    </div>
    <div class="click" @click="changeVideoStatus">
      <video-player ref="videoPlayer" class="player-video" :playsinline="false" :options="playOptions"
        @ready="onPlayerReady" @play="onPlayerPlay($event)" @pause="onPlayerPause($event)" @ended="onPlayerEnd($event)"
        @waiting="onPlayerWaiting($event)" @playing="onPlayerPlaying($event)" @loadeddata="onPlayerLoadeddata($event)"
        @timeupdate="onPlayerTimeupdate($event)" @statechanged="playerStateChanged($event)" />
      <img class="img" src="@/assets/tikkon/tikkon_0/tikkon_0_04.png" alt="">
    </div>
    <img class="img" src="@/assets/tikkon/tikkon_0/tikkon_0_06.png" alt="">
    <img class="img" src="@/assets/tikkon/tikkon_0/tikkon_0_07.png" alt="">
    <img class="img" src="@/assets/tikkon/tikkon_0/tikkon_0_08.png" alt="">
    <img class="img" @click="clickTaba(3)" src="@/assets/tikkon/tikkon_0/tikkon_0_09.png" alt="">
    <img class="img" @click="clickTaba(2)" src="@/assets/tikkon/tikkon_0/tikkon_0_10.png" alt="">
    <img class="img" @click="clickTaba(5)" src="@/assets/tikkon/tikkon_0/tikkon_0_11.png" alt="">
    <img class="img" @click="clickTaba(4)" src="@/assets/tikkon/tikkon_0/tikkon_0_12.png" alt="">
    <img class="img" @click="clickTaba(0)" src="@/assets/tikkon/tikkon_0/tikkon_0_13.png" alt="">
    <img class="img" @click="clickTaba(1)" src="@/assets/tikkon/tikkon_0/tikkon_0_14.png" alt="">
    <img class="img" @click="clickIndex" src="@/assets/tikkon/tikkon_0/tikkon_0_15.png" alt="">
    <img class="img" src="@/assets/tikkon/tikkon_0/tikkon_0_16.png" alt="">
    <!-- <div class="click">
      <div class="tobtn" @click="clickIndex"></div>
      <img class="img" src="@/assets/tikkon/tikkon_3/tikkon_3_06.jpg" alt="">
    </div> -->
  </div>
</template>

<script>
import axios from '../../http'
import CryptoJS from 'crypto-js'
import wx from "weixin-js-sdk"
export default {
  name: 'TikkonBuild',
  components: {

  },
  data() {
    return {
      videoIsPlay: false,
      playedTime: 0,
      currentTime: 0,
      maxTime: 0,
      playOptions: {
        height: "200px",
        width: "100%",
        playbackRates: [1.0], // 可选的播放速度
        autoplay: false, // 如果为true,浏览器准备好时开始回放
        controls: true,
        muted: false, // 默认情况下静音播放
        loop: false, // 是否视频一结束就重新开始
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据，auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值，值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小，换句话说，它将按比例缩放以适应其容器
        sources: [
          {
            type: "video/mp4", // 类型
            src: 'https://inc.tikkon.cn/image/ruoyi/11.8.mp4'
            // src: require("./1.mp4"), // url地址，在使用本地的资源时，需要用require()引入，否则控制台会报错
          },
        ],
        poster: require("../../assets/tikkon/tikkon_0/tikkon_poster.jpg"), // 设置封面地址
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息
        controlBar: {
          currentTimeDisplay: true,
          progressControl: true,  // 是否显示进度条
          playbackRateMenuButton: true, // 是否显示调整播放倍速按钮
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: true, // 是否显示剩余时间功能
          fullscreenToggle: true, // 是否显示全屏按钮
        },
      },

    }
  },
  methods: {
    clickIndex() {
      this.$router.push('/tikkon');
      document.body.scrollTop = document.documentElement.scrollTop = 0;

    },
    clickTaba(index) {
      const url = {
        0: "/tikkon/research",
        1: "/tikkon/technology",
        2: '/tikkon/city',
        3: '/tikkon/service',
        4: '/tikkon/cultural-tourism',
        5: '/tikkon/build',
      };
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      this.$router.push(url[index]);
    },
    getWXcode() {
      axios.get('/auth/weChatMPTicket', {}).then((res) => {
        const { noncestr, ticket, timestamp } = res.data.data || {}
        const data = {
          noncestr: noncestr,
          jsapi_ticket: ticket,
          timestamp: timestamp,
          url: window.location.href.split('#')[0],
        }
        const code = `jsapi_ticket=${data.jsapi_ticket}&noncestr=${data.noncestr}&timestamp=${data.timestamp}&url=${data.url}`
        const key = CryptoJS.SHA1(code).toString();
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: 'wx507a817966a379b5', // 必填，公众号的唯一标识
          timestamp: data.timestamp, // 必填，生成签名的时间戳
          nonceStr: data.noncestr, // 必填，生成签名的随机串
          signature: key,// 必填，签名
          jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'] // 必填，需要使用的JS接口列表
        });
        // eslint-disable-next-line no-undef
        wx.ready(function () {
          wx.updateAppMessageShareData({
            title: '天空市政工程（常州）有限公司', // 分享标题
            desc: '城市系统性工程方案提供商\n市政·园林·装饰·安装', // 分享描述
            link: 'http://www.tikkon.cn/tikkon/build', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: 'https://inc.tikkon.cn/image/ruoyi/2024/10/17/9f16689dc0cc462ea838c45b53a65679.jpg', // 分享图标
            success: function () {
              console.log('设置成功')
              // 设置成功
            }
          })
          // 分享到朋友圈
          wx.updateTimelineShareData({
            title: '天空市政工程（常州）有限公司', // 分享标题
            link: 'http://www.tikkon.cn/tikkon/build', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: 'https://inc.tikkon.cn/image/ruoyi/2024/10/17/9f16689dc0cc462ea838c45b53a65679.jpg', // 分享图标
            success: function () {
              console.log('设置成功')
              // 设置成功
            }
          })
        })
        // eslint-disable-next-line no-undef
        wx.error(function (res) {
          console.log(res, 'xxxxxxxxxxxxx')
          // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
        });
      })
    },



    // 准备好了
    onPlayerReady() {
      console.log("准备好了");
    },
    // 视频播放
    onPlayerPlay(player) {
      console.log('播放了');
      console.log(player);
      let playTime = 0;
      if (
        Number(Math.floor(this.playedTime)) ===
        Number(Math.floor(player.duration()))
      ) {
        this.playedTime = 0;
        playTime = 0;
      } else if (
        Number(Math.floor(player.currentTime())) !==
        Number(Math.floor(this.playedTime))
      ) {
        playTime = this.playedTime;
        player.currentTime(playTime)
      }
    },
    // 视频暂停的
    onPlayerPause(player) {
      console.log('暂停中');
      console.log(player);
      this.playedTime = player.currentTime();
    },
    // 视频播放完
    onPlayerEnd(player) {
      console.log('播放结束了');
      console.log(player);
    },
    // DOM元素上的readyState更改导致播放停止
    onPlayerWaiting(player) {
      console.log("播放停止中");
      console.log(player);
    },
    // 视频已开始播放
    onPlayerPlaying(player) {
      console.log("开始播放了");
      console.log(player);
    },
    // 当播放器在当前播放位置下载数据时触发
    onPlayerLoadeddata(player) {
      console.log("开始下载数据");
      console.log(player);
    },
    // 当前播放位置发生变化时触发
    onPlayerTimeupdate(player) {
      console.log("播放位置变化了");
      console.log(player);
      // let timeDisplay = player.currentTime();
      // if (timeDisplay - this.currentTime > 1) {
      //   player.currentTime(this.currentTime > this.maxTime ? this.currentTime : this.maxTime);
      // }
      // this.currentTime = player.currentTime();
      // this.maxTime = this.currentTime > this.maxTime ? this.currentTime : this.maxTime;
    },
    //播放状态改变
    playerStateChanged(playerCurrentState) {
      console.log("播放状态变化了");
      console.log(playerCurrentState);
    },
    // 手动暂停视频的播放
    pause() {
      // 视频播放器的方法调用，要使用this.$refs.videoPlayer.player这个对象去调用
      this.$refs.videoPlayer.player.pause()
    }
  },
  mounted() {
    this.getWXcode()
  },
}

</script>

<style lang="scss" scoped>
.img {
  width: 100%;
  margin: 0%;
  position: relative;
  display: block;
  z-index: 1;
}

.click {
  width: 100%;
  position: relative;

  .tobtn {
    position: absolute;
    width: 200px;
    height: 100px;
    right: 0;
    bottom: 0;
    z-index: 9;
  }

  .btn_list {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 9;
    left: 0;
    top: 0;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding-top: 60px;

    .btn {
      width: 50%;
      height: 190px;
    }

  }

  .player-video {
    position: absolute;
    z-index: 9;
    left: 0;
    bottom: 0;
    width: 100%;
  }

  :deep(.video-js .vjs-big-play-button) {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    height: 60px;
    border-radius: 50%;

    .vjs-icon-placeholder {
      position: absolute;
      top: 12%;
      left: 25%;
      font-size: 30px;
    }

  }
}
</style>
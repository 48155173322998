<template>
    <div id="footer" class="footer">
        <div class="footerPage">
            <div class="leftdlist">
                <div class="left-text">
                    <div class="left-name name_color">
                        走进天空
                    </div>
                    <div class="h_flex">
                        <div class="left-names" @click="clickTaba(0)">
                            企业概况
                        </div>
                        <div class="left-names" @click="clickTaba(1)">
                            企业文化
                        </div>
                        <div class="left-names" @click="clickTaba(2)">
                            企业架构
                        </div>
                        <div class="left-names" @click="clickTaba(3)">
                            主要团队
                        </div>
                        <div class="left-names" @click="clickTaba(4)">
                            荣誉历程
                        </div>
                        <div class="left-names" @click="clickTaba(5)">
                            合作伙伴
                        </div>
                    </div>

                </div>
                <div class="left-text left-text-none">
                    <div class="left-name left-name-border name_color">
                        业务板块
                    </div>
                    <div class="flex-text">
                        <div class="is_hover" @click="clickTab(3)">
                            <div class="list_value_new">TIKKON®天空城市服务</div>
                            <div class="list_text">400-888-0519</div>
                            <div class="list_text">service.tikkon.cn</div>
                            <div class="list_text">物业·环卫·安保·电梯</div>
                        </div>
                        <div class="is_hover" @click="clickTab(2)">
                            <div class="list_value_new">TIKKON®天空城市发展</div>
                            <div class="list_text">0519-8666-8188</div>
                            <div class="list_text">share.tikkon.cn</div>
                            <div class="list_text">能源·资产·股权·合作</div>
                        </div>
                        <div class="is_hover" @click="clickTab(5)">
                            <div class="list_value_new">TIKKON®天空市政工程</div>
                            <div class="list_text">0519-8666-8988</div>
                            <div class="list_text">construction.tikkon.cn</div>
                            <div class="list_text">市政·园林·装饰·安装</div>
                        </div>
                        <div class="is_hover" @click="clickTab(4)">
                            <div class="list_value_new">TIKKON®天空文旅发展</div>
                            <div class="list_text">0519-8666-8688</div>
                            <div class="list_text">joy.tikkon.cn</div>
                            <div class="list_text">游乐·萌宠·潮玩·酒店</div>
                        </div>
                        <div class="is_hover" @click="clickTab(0)">
                            <div class="list_value_new">TIKKON®天空传媒发展</div>
                            <div class="list_text">400-688-6661</div>
                            <div class="list_text">research.tikkon.cn</div>
                            <div class="list_text">品牌·电商·视频·手游</div>
                        </div>
                        <div class="is_hover" @click="clickTab(1)">
                            <div class="list_value_new">TIKKON®天空信息技术</div>
                            <div class="list_text">0519-8666-1188</div>
                            <div class="list_text">smart.tikkon.cn</div>
                            <div class="list_text">数据·智能·安全·集成</div>
                        </div>
                  
                   
                    </div>
                </div>
                <div class="left-text" @click="tonews">
                    <div class="left-name name_color">
                        新闻中心
                    </div>

                </div>
                <div class="left-text" @click="towliaison">
                    <div class="left-name name_color">
                        加入我们
                    </div>

                </div>
            </div>
            <div class="right">
                <div class="right-value">
                    <div v-for="item in 1" :key="item" class="img">
                        <img class="ma" src="../assets/images/wx.jpg" alt="">
                        <img src="../assets/images/iconwx.jpg" alt="">
                    </div>
                </div>
                <div class="text">关注我们</div>
                <div class="text">第一时间了解天空集团动态讯息</div>
                <div class="message">
                    <div>苏ICP备05106929号-1</div>
                    <div>©2024 天空控股集团有限公司 版权所有 </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: 'FooterInfo',
    components: {
    },
    props: {
        msg: String
    },
    data() {
        return {

            isStatus: true
        }
    },
    methods: {
        isPc() {
            var userAgentInfo = navigator.userAgent;
            var agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
            var flag = true;
            for (var i = 0; i < agents.length; i++) {
                if (userAgentInfo.indexOf(agents[i]) > 0) {
                    flag = false;
                    break;
                }
            }
            this.isStatus = flag;
        },
        clickTaba(index) {
            
            const url = {
                0: "/vaski-situation",
                1: "/vaski-culture",
                2: '/vaski-structure',
                3: '/vaski-member',
                4: '/vaski-experience',
                5: '/vaski-cooperation'
            };
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            this.$router.push(url[index]);
        },
        clickTab(index) {
            // const url = {
            //     0: "http://www.research.tikkon.cn/business-research",
            //     1: 'http://www.smart.tikkon.cn/business-science',
            //     2: 'http://www.share.tikkon.cn/business-city',
            //     3: 'http://www.service.tikkon.cn/business-service',
            //     4: 'http://www.joy.tikkon.cn/business-tourism',
            //     5: 'http://www.construction.tikkon.cn/business-government'
            // };
            // // document.body.scrollTop = document.documentElement.scrollTop = 0;
            // // this.$router.push(url[index]);
            // // window.history.href=url[index]
            // window.open(url[index], '_blank');

            const url = {
                0: "/business-research",
                1:'/business-science',
                2:'/business-city',
                3:'/business-service',
                4:'/business-tourism',
                5:'/business-government'
            };
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            this.$router.push(url[index]);
        },
        tonews(){
            this.$router.push('/news');
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        },
        towliaison(){
            this.$router.push('/contact-information');
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        }
    },
    mounted() {
        this.isPc()

    },
}
</script>

<!-- Add "scoped" attribute to divmit CSS to this component only -->
<style lang="scss" scoped>
/*引用字库*/
@font-face {
    font-family: "SourceHanSansCN";
    src: url("../assets/font/SourceHanSansCN-Normal.otf");
}

/*引用字库*/
@font-face {
    font-family: "SourceHanSansCNBold";
    src: url("../assets/font/SourceHanSansCN-Bold.otf");
}

.footer {
    width: 100%;
    height: 300px;
    background-color: #f3f3f3;
    position: relative;
    display: flex;
    justify-content: center;
    cursor: default;

    .footerPage {
        width: 1400px;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 50px 0;

        .leftdlist {
            display: flex;

            .is_hover {
                color: #7C7C7C;
                margin-bottom: 20px;
                margin-right: 20px;
                width: 200px;

                &:hover {
                    color: #286ea2;

                }
            }

            .left-text {
                margin-right: 60px;

                .flex-text {
                    display: flex;
                    flex-wrap: wrap;
                    width: 700px;
                }

                .left-name {
                    font-weight: 900;
                    font-size: 16px;
                    margin-bottom: 20px;
                    font-family: SourceHanSansCN;

                }
                .name_color{
                    color: #323232;
                }
                .left-name-border {
                    position: relative;

                    &::after {
                        content: '';
                        width: calc(100% - 170px);
                        border-bottom: dashed 1px #7C7C7C;
                        position: absolute;
                        top: 10px;
                        left: 80px;
                    }
                }

                .list_value_new {
                    font-family: SourceHanSansCNBold;
                }

                .left-names {
                    font-weight: 900;
                    font-size: 14px;
                    font-family: SourceHanSansCN;
                    margin-bottom: 8px;
                    color: #7C7C7C;
                }

                .list_text {
                    font-size: 12px;
                    line-height: 16px;
                }
            }

            .left-text-none {
                margin: 0;
            }

        }

        .right {
            .right-value {
                display: flex;
                width: 200px;
                margin-bottom: 20px;
                .img {
                    width: 80px;
                    height: 80px;
                    position: relative;
                    top: -20px;
                    left: -20px;
                    margin-bottom: -20px;
                    img {
                        width: 40px;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%,-50%);
                        z-index: 8;
                    }

                    .ma {
                        position: absolute;
                        width: 80px;
                        height: 80px;
                        background-color: #fff;
                        box-sizing: border-box;
                        padding: 3px;
                        border-radius: 5px;
                        transition: opacity 0.5s ease-in-out;
                        display: block;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%,-50%);
                        z-index: 9;
                        display: none
                    }
                    &:hover {
                        img {
                        width: 100%;
                        display: none
                    }
                        .ma {
                        display: block
                        }
                    }
                }
            }

            .text {
                font-size: 14px;
                line-height: 30px;
                font-family: SourceHanSansCN;
                font-weight: 800;
            }

            .message {
                font-size: 12px;
                margin-top: 50px;
                color: #676767;
            }
        }
    }
}


@media (max-width: 767px) {
    .footer {
        width: 100%;
        height: auto;
        background-color: #f3f3f3;
        position: relative;
        display: block;
        justify-content: center;
        cursor: default;

        .footerPage {
            width: 100%;
            display: block;
            justify-content: space-between;
            box-sizing: border-box;
            padding: 20px 0;

            .leftdlist {
                display: block;
                justify-content: space-around;
                text-align: center;

                .is_hover {
                    color: #7C7C7C;
                    margin-bottom: 20px;
                    width: 30%;
                    margin-left: 0;

                    &:hover {
                        color: #286ea2;

                    }
                }

                .left-text {
                    margin-right: 0px;

                    .h_flex {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;

                        .left-names {
                            width: 30%;
                        }
                    }

                    .flex-text {
                        display: flex;
                        width: 100%;
                        justify-content: space-around;
                    }



                    .left-name {
                        font-weight: 900;
                        font-size: 16px;
                        margin-bottom: 20px;
                        font-family: SourceHanSansCN;

                    }
                    .left-name-border{
                        &::after {
                        content: '';
                        width: calc(100% - 170px);
                        border-bottom: dashed 1px #7C7C7C;
                        position: absolute;
                        top: 30px;
                        left: 80px;
                    }
                    }
                }
            }

            .right {
                .right-value {
                    display: none !important;
                    display: flex;
                    width: 100%;
                    justify-content: space-around;
                    margin-bottom: 10px;

                    .img {
                        width: 40px;
                        position: relative;

                        img {
                            width: 100%;
                        }

                        .ma {
                            position: absolute;
                            width: 100px;
                            height: 100px;
                            background-color: #fff;
                            left: -100px;
                            top: -50px;
                            display: none;
                            box-sizing: border-box;
                            padding: 10px;
                        }

                        &:hover {
                            .ma {
                                display: block;
                            }
                        }
                    }
                }

                .text {
                    font-size: 14px;
                    line-height: 30px;
                    text-align: center;
                    font-family: SourceHanSansCN;
                    font-weight: 800;
                }

                .message {
                    font-size: 12px;
                    margin-top: 20px;
                    color: #676767;
                    text-align: center;
                }
            }
        }
    }

}
</style>
<template>
  <div class="vaski">
    <img class="vaski-img" src="@/assets/images/xcoruovs.png" alt="" />
    <div class="vaski-bg">
      <div class="vaski-tap">
        <div class="vaski-left">
          <span class="text">业务板块</span>
          <el-icon class="icon" color="#666666">
            <ArrowRight />
          </el-icon>
          <span class="text">{{nameLisr[tabIndex]}}</span>
        </div>
        <div class="demo-tabs">
          <div class="tabs-name-bars" @click="clickTab(3)">城市服务</div>
          <div class="tabs-name-bars" @click="clickTab(2)">城市发展</div>
          <div class="tabs-name-bars" @click="clickTab(5)">市政工程</div>
          <div class="tabs-name-bars" @click="clickTab(4)">文旅发展</div>
          <div class="tabs-name-bars" @click="clickTab(0)">传媒发展</div>
          <div class="tabs-name-bars" @click="clickTab(1)">信息技术</div>
          <div class="tab-bottm" :style="`left:${left}px`"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TabBar",
  components: {},
  props: {
    tabIndex: Number,
  },
  data() {
    return {
      left: 0,
      nameLisr:[
        '城市服务',
        '城市发展',
        '市政工程',
        '文旅发展',
        '传媒发展',
        '信息技术',
      ]
    };
  },
  methods: {
    clickTab(index) {
      const url = {
        0: "/business-research",
        1:'/business-science',
        2:'/business-city',
        3:'/business-service',
        4:'/business-tourism',
        5:'/business-government'
      };
      this.$router.push(url[index]);
    },
    getDome() {
      const indexList ={
        3:0,
        2:1,
        5:2,
        4:3,
        0:4,
        1:5,
      }
      const doms = document.getElementsByClassName("tabs-name-bars")[indexList[this.tabIndex]];
      this.left = doms.offsetLeft;
    },
  },
  mounted() {
    this.getDome();
  },
};
</script>

<style lang="scss" scoped>
.vaski {
  width: 100%;
  overflow: hidden;
  cursor: default;

  .vaski-img {
    width: 100%;
  }

  .vaski-bg {
    width: 100%;
    border-bottom: solid 2px #f3f3f3;

    .vaski-tap {
      width: 1400px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: solid 1px #f3f3f3;

      .vaski-left {
        font-size: 16px;
        display: flex;
        align-items: center;

        .text {
          color: #666;
        }

        .icon {
          margin: 0 10px;
        }
      }

      .demo-tabs {
        height: 100%;
        display: flex;
        position: relative;
        color: #000;

        .tabs-name-bars {
          font-size: 20px;
          width: 130px;
          line-height: 80px;
          text-align: center;
          font-size: 16px;
          cursor: default;
        }

        .tab-bottm {
          width: 130px;
          height: 0%;
          border-bottom: solid 4px #409eff;
          position: absolute;
          bottom: -3px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .vaski {
    .vaski-img {
      width: 250%;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }

    .vaski-bg {
      width: 100%;
      border-bottom: solid 2px #f3f3f3;

      .vaski-tap {
        width: 100%;
        margin: 0 auto;
        display: block;
        justify-content: space-between;
        align-items: center;
        border-bottom: solid 1px #f3f3f3;

        .vaski-left {
          font-size: 16px;
          display: flex;
          margin-left: 20px;
          height: 40px;

          .text {
            color: #666;
          }

          .icon {
            margin: 0 10px;
          }
        }

        .demo-tabs {
          height: 100%;
          display: flex;
          position: relative;
          color: #000;
          justify-content: space-between;

          .tabs-name-bars {
            font-size: 20px;
            width: 60px;
            line-height: 50px;
            text-align: center;
            font-size: 12px;
            cursor: default;
          }

          .tab-bottm {
            width: 60px;
            height: 0%;
            border-bottom: solid 4px #409eff;
            position: absolute;
            bottom: -3px;
          }
        }
      }
    }
  }
}
</style>

<template>
    <div id="merchant-list" class="list" v-if="isStatus">
        <div class="list-name">城市综合服务解决方案供应商</div>
        <div class="list-in" >
            <el-row :gutter="20">
                <el-col :span="8" v-for="item in imgList" :key="item">
                    <div class="list-item">
                        <div class="item-bg">
                            <div class="item-value">
                                <div class="item-name">{{item.name}}</div>
                                <div class="item-br"></div>
                                <div class="item-text">{{item.text}}</div>
                            </div>
                        </div>
                        <img class="item-img" :src="item.url" alt="">
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="list-in" >
            <el-row :gutter="20">
                <el-col :span="8" v-for="item in imgLists" :key="item">
                    <div class="list-item">
                        <div class="item-bg">
                            <div class="item-value">
                                <div class="item-name">{{item.name}}</div>
                                <div class="item-br"></div>
                                <div class="item-text">{{item.text}}</div>
                            </div>
                        </div>
                        <img class="item-img" :src="item.url" alt="">
                    </div>
                </el-col>
            </el-row>
        </div>

    </div>
    <div class="list" v-else>
        <div class="list-name">城市综合服务解决方案供应商</div>
        <div class="list-in">
            <el-carousel ref="swiperlist" :height="`470px`" :autoplay="true" :interval="6000" @touchstart="startSwipe"
                @touchmove="swipe" @touchend="endSwipe" trigger="click" indicator-position="none">
                <el-carousel-item v-for="item in [...imgList,...imgLists]" :key="item">
                    <div class="list-item">
                        <div class="item-bg">
                            <div class="item-value">
                                <div class="item-name">{{item.name}}</div>
                                <div class="item-br"></div>
                                <div class="item-text">{{item.text}}</div>
                            </div>
                        </div>
                        <img class="item-img" :src="item.url" alt="">
                    </div>
                </el-carousel-item>
            </el-carousel>

        </div>
    </div>
</template>

<script>
import img0 from '../assets/images/img0.png'
import img1 from '../assets/images/img1.png'
import img2 from '../assets/images/img2.png'
import img3 from '../assets/images/img3.png'
import img4 from '../assets/images/img4.png'
import img5 from '../assets/images/img5.png'
export default {
    name: 'MerchantList',
    components: {
    },
    props: {
        msg: String
    },
    data() {
        return {
            height: 880,
            startX: 0,
            endStartX: 0,
            left: -100,
            leftb: -100,
            isStatus: true,
            imgList:[
            {
                    name:'城市服务',
                    text:'物业·环卫·安保·电梯',
                    url:img3
                },
        
                {
                    name:'城市发展',
                    text:'能源·资产·股权·合作',
                    url:img2
                },
                {
                    name:'市政工程',
                    text:'市政·园林·装饰·安装',
                    url:img5
                }
            ],
            imgLists:[
              
                {
                    name:'文旅发展',
                    text:'游乐·萌宠·潮玩·酒店',
                    url:img4
                },
                {
                    name:'传媒发展',
                    text:'品牌·电商·视频·手游',
                    url:img0
                },
                {
                    name:'信息技术',
                    text:'数据·智能·安全·集成',
                    url:img1
                },
            ]
        }
    },
    methods: {
        isPc() {
            var userAgentInfo = navigator.userAgent;
            var agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
            var flag = true;
            for (var i = 0; i < agents.length; i++) {
                if (userAgentInfo.indexOf(agents[i]) > 0) {
                    flag = false;
                    break;
                }
            }
            this.isStatus = flag;
        },
        startSwipe(e) {
            this.startX = e.touches[0].clientX
        },
        swipe(e) {
            this.endStartX = e.touches[0].clientX
        },
        endSwipe() {
            const swiperlist = this.$refs.swiperlist
            if (this.endStartX !== 0) {
                if (this.endStartX - this.startX <= -50) {
                    swiperlist.next()
                }
                if (this.endStartX - this.startX >= 50) {
                    swiperlist.prev()
                }
            }

            this.endStartX = 0
            this.startX = 0
        },
    },
    mounted() {
        this.isPc()
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    // 组件进入可视区域
                    console.log(entry.target.style.animation)
                    entry.target.style.animation = 'example1 1s ease-out 0s backwards';
                }
            });
        });
        // 获取需要监听的组件
        const target = document.getElementById('merchant-list');

        // 开始监听
        observer.observe(target);
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.list {
    width: 1400px;
    box-sizing: border-box;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 100px;

    .list-in {
        margin-bottom: 20px;
        position: relative;
        // transform: translateX(-50%);
        // transition: left 0.5s ease-in-out;
        animation: example1 1s ease-out 0s backwards;
    }

    @keyframes example1 {
        0% {
            left: -100%;
            opacity: 0;
        }
      
        100% {
            left: 0;
            opacity: 1;
        }
    }

    .list-name {
        width: 100%;
        text-align: center;
        font-size: 40px;
        color: #005186;
        margin-top: 60px;
        line-height: 110px;
        margin-bottom: 20px;
    }

    .list-item {
        width: 100%;
        height: 470px;
        // background-color: #f00;
        position: relative;

        .item-img {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
        }

        .item-bg {
            position: absolute;
            width: 100%;
            height: 80px;
            bottom: 0;
            left: 0;
            background-color: rgba(6, 65, 143, .5);
            box-sizing: border-box;
            transition: height 0.5s ease-in-out;
            overflow: hidden;
            z-index: 99;

            .item-value {
                position: absolute;
                left: 40px;
                top: 0;
                transition: top 0.5s ease-in-out;

                .item-name {
                    line-height: 80px;
                    font-size: 25px;
                    position: relative;
                    color: #fff;
                }

                .item-br {
                    width: 20px;
                    height: 2px;
                    position: relative;
                    background-color: #fff;
                }

                .item-text {
                    font-size: 25px;
                    color: #fff;
                    line-height: 30px;
                    position: relative;
                    margin-top: 30px;
                }
            }

        }

        &:hover {
            .item-bg {
                height: 100%;

                .item-value {
                    top: 130px;
                }

                // .item-name {
                //     top: 130px;

                // }

                // .item-br {
                //     top: 130px;
                // }

                // .item-text {
                //     top: 130px;
                // }
            }
        }
    }
}


@media (max-width: 767px) {
    .list {
        width: 100%;
        margin-bottom: 20px;

        .list-in {
            margin-bottom: 20px;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            transition: left 0.5s ease-in-out;

        }

        .list-name {
            width: 100%;
            text-align: center;
            font-size: 20px;
            color: #005186;
            margin-top: 20px;
            line-height: 110px;
            margin-bottom: 20px;

        }

        .list-item {
            width: 100%;
            height: 470px;
            // background-color: #f00;
            position: relative;
            margin-bottom: 20px;

            .item-img {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                z-index: 9;
            }

            .item-bg {
                position: absolute;
                width: 100%;
                height: 80px;
                bottom: 0;
                left: 0;
                background-color: #014572ab;
                box-sizing: border-box;
                transition: height 0.5s ease-in-out;
                overflow: hidden;
                z-index: 99;

                .item-value {
                    position: absolute;
                    left: 40px;
                    top: 0;
                    transition: top 0.5s ease-in-out;

                    .item-name {
                        line-height: 80px;
                        font-size: 25px;
                        position: relative;
                        color: #fff;
                    }

                    .item-br {
                        width: 20px;
                        height: 2px;
                        position: relative;
                        background-color: #fff;
                    }

                    .item-text {
                        font-size: 25px;
                        color: #fff;
                        line-height: 30px;
                        position: relative;
                        margin-top: 30px;
                    }
                }

            }

            &:hover {
                .item-bg {
                    height: 100%;

                    .item-value {
                        top: 130px;
                    }

                    // .item-name {
                    //     top: 130px;

                    // }

                    // .item-br {
                    //     top: 130px;
                    // }

                    // .item-text {
                    //     top: 130px;
                    // }
                }
            }
        }
    }

}
</style>